<template>
    <v-container class="pt-8">
        <h1 class="display-1">Política de Privicitat</h1>
        <v-card v-for="paragraph in paragraphs"
                class="pa-5 mt-4"
        >
            <v-card-title class="headline">{{paragraph.title}}</v-card-title>
            <v-card-text class="title font-weight-regular">
                <template v-for="line in paragraph.text.split('\n')">{{line}}<br></template>
            </v-card-text>
        </v-card>

    </v-container>
</template>

<script>
export default {
  name: 'Cookies',
  data: () => ({
    paragraphs: [
      {
        title: 'Política de privicitat',
        text: `
                   Si ets aquí és perquè vols saber més sobre les obligacions i drets que et corresponen com a usuari d'aquesta web https://examenselectivitat.cat i això està molt bé. El nostre deure és informar-te i el teu estar degudament informat.
En aquesta Política de Privacitat t'informarem amb total transparència sobre la finalitat d'aquest lloc web i tot el que afecta les dades que ens facilitis, així com de les obligacions i drets que et corresponen.
Per a començar, has de saber que aquest lloc web s'adapta a la normativa vigent en relació amb la protecció de dades, la qual cosa afecta les dades personals que ens facilitis amb el teu consentiment exprés i a les cookies que utilitzem perquè aquest lloc web funcioni correctament i pugui desenvolupar la seva activitat.
El *RGPD (Reglament (UE) 2016/679 del Parlament Europeu i del Consell de 27 d'abril de 2016 relatiu a la protecció de les persones físiques) que és la nova normativa de la Unió Europea que unifica la regulació del tractament de les dades personals en els diferents països de la UE.
La *LOPD (Llei orgànica 15/1999, de 13 de desembre, de Protecció de Dades de Caràcter Personal i Reial decret 1720/2007, de 21 de desembre, el Reglament de desenvolupament de la *LOPD) que regula el tractament de les dades personals i les obligacions que hem d'assumir els responsables d'una web o un blog a l'hora de gestionar aquesta informació.
La *LSSI (Llei 34/2002, d'11 de juliol, de Serveis de la Societat de la Informació i Comerç Electrònic) que regula les transaccions econòmiques mitjançant mitjans electrònics, com és el cas d'aquest blog.
`
      },
      {
        title: 'Condicions d\'ús',
        text: `Has de saber, per a la teva tranquil·litat, que sempre et sol·licitarem el teu consentiment exprés per a recaptar les teves dades amb la corresponent finalitat especificada en cada cas, la qual cosa implica que, en cas d'atorgar aquest consentiment, has llegit i acceptat aquesta Política de Privacitat.
En el moment en què accedeixis i utilitzis aquesta web, assumeixes la teva condició d'usuari amb els teus corresponents drets i obligacions.
Si ets major de 13 anys, podràs registrar-te com a usuari en aquest lloc web sense el previ consentiment dels teus pares o tutors.
Si ets menor de 13 anys, necessitaràs el consentiment dels teus pares o tutors per al tractament de les teves dades personals.
`
      },
      {
        title: 'Registre i finalitat de les teves dades',
        text: `En funció del formulari o secció a la qual accedeixis, et sol·licitarem exclusivament les dades necessàries amb les finalitats descrites a continuació. En tot moment, hauràs de donar el teu consentiment exprés, quan et sol·licitem informació personal amb les següents finalitats:
Les finalitats concretes que particularment s'indiquin en cadascuna de les pàgines o seccions on aparegui el formulari de registre o contacte electrònic.
Amb caràcter general, per a atendre les teves sol·licituds, comentaris, consultes o qualsevol tipus de petició que realitzis com a usuari a través de qualsevol de les formes de contacte que posem a la teva disposició
Per a informar-te sobre consultes, peticions, activitats, productes, novetats i/o serveis; via e-mail, fax, Whatsapp, Skype, telèfon proporcionat, *sms i *mms.
Per a enviar-te comunicacions comercials o publicitàries a través de qualsevol altre mitjà electrònic o físic, que possibiliti realitzar comunicacions.
Aquestes comunicacions, sempre seran relacionades amb els nostres productes, serveis, novetats o promocions, així com aquells productes o serveis que puguem considerar del teu interès i que puguin oferir col·laboradors, empreses o “*partners” amb els quals mantinguem acords de promoció o col·laboració comercial.
`
      }
    ]
  })
}
</script>
